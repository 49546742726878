import React, { useEffect, useState } from "react";
import { Button, message, PageHeader, Table, Tag } from "antd";
import { useHistory } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import moment from "moment";
import "moment/locale/ko";
/**
 * 검수 대상 목록
 * @returns {JSX.Element}
 * @constructor
 */
const InspcTrgtsList = ({ projectService, projectInfo, getWorkFolderInfo }) => {
  const [open, setOpen] = React.useState(false);
  const [taskValue, setTaskValue] = React.useState([]);
  const [isLoading, setLoading] = useState(true);
  const [inspcTrgts, setInspcTrgtsList] = useState([]);

  let projectNo = projectInfo.projectNo;

  const history = useHistory();

  useEffect(() => {
    projectService
      .getInspcTrgtsList(projectNo)
      .then((r) => {
        console.log(r);
        setInspcTrgtsList(r);
        setLoading(false);
      })
      .catch((error) => {
        message.error(error.message);
      });
  }, [projectService]);

  const handleClickOpen = (value) => {
    setTaskValue(value);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onInspectStart = (value) => {
    projectService
      .onJobStart(projectNo, value.taskFolderNo, value.role)
      .then((r) => {
        getWorkFolderInfo(value);
        if (value.fileType == "VIDEO") {
          history.push("/annotationVideoInspection");
        } else {
          history.push("/annotationImage/all");
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const columns = [
    // {
    //   title: "No",
    //   dataIndex: "rowNum",
    //   key: "rowNum",
    //   width: "5%",
    //   align: "center",
    // },
    {
      title: "태스크",
      dataIndex: "taskName",
      key: "taskName",
      width: "14%",
    },
    {
      title: "폴더",
      width: "30%",
      render: (value) => {
        return <>{value.folderPath}</>;
      },
    },
    {
      title: "작업자",
      dataIndex: "workId",
      key: "workId",
      width: "7%",
    },
    // {
    //     title: 'AI 선가공 유형',
    //     dataIndex: 'dataType',
    //     key: 'dataType',
    //     width: "10%",
    //     render: (value) => {
    //         let tag = "오류";
    //         let color = "red";
    //         switch (value) {
    //             case "FACE":
    //                 tag = "비식별화(얼굴)";
    //                 color = "geekblue";
    //                 break;
    //             case "OBJ":
    //                 tag = "객체인식";
    //                 color = "green";
    //                 break;
    //             case "SKE17":
    //                 tag = "스켈레톤17";
    //                 color = "purple";
    //                 break;
    //             case "SKE24":
    //                 tag = "스켈레톤24";
    //                 color = "purple";
    //                 break;
    //             case "OCR":
    //                 tag = "OCR";
    //                 color = "orange";
    //                 break;
    //             case "LP":
    //                 tag = "비식별화(번호판)";
    //                 color = "orange";
    //                 break;
    //             case "FL":
    //                 tag = "비식별화";
    //                 color = "orange";
    //                 break;
    //             case "CAPTIONING":
    //                 tag = "캡셔닝";
    //                 color = "green"
    //                 break;
    //             default:
    //                 tag = "선가공 없음";
    //                 color = "darkgray";
    //                 break;
    //         }
    //         return (
    //             <Tag color={color} style={{ fontSize: '14px'}}>
    //                 {tag}
    //             </Tag>
    //         );
    //     }
    // },
    // {
    //     title: '파일유형',
    //     dataIndex: 'fileType',
    //     key: 'fileType',
    //     width: "8%",
    //     render: (value) => {
    //         return (
    //             <Tag color={"cyan"} key={value} style={{ fontSize: '14px'}}>
    //                 {value}
    //             </Tag>
    //         );
    //     }
    // },
    {
      title: "전체 파일 수",
      dataIndex: "fileCount",
      key: "fileCount",
      width: "7%",
    },
    {
      title: "생성일",
      dataIndex: "regDate",
      key: "regDate",
      width: "7%",
      render: (value) => moment(value).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "시작",
      width: "4%",
      render: (value) => {
        return (
          <>
            <Button onClick={() => handleClickOpen(value)}>검수 시작</Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      {/*검수 대상 목록 : 작업 완료 상태, 1차검수 완료 상태(자기가 한건 안뜸)의 TASK들을 리스트로 출력하는 페이지.
            입력값 : project_no
            출력값 :
            TASK_NO(정렬기준) ,  task_name, type, 파일개수(task_file 테이블에서), REG_DATE*/}
      <PageHeader className="site-page-header" title="검수 대상 목록" />
      <Table
        columns={columns}
        dataSource={inspcTrgts}
        rowKey={(record) => record.taskFolderNo}
        loading={isLoading}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`알림`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {taskValue.taskName} 검수를 시작하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            취소
          </Button>
          <Button
            onClick={() => onInspectStart(taskValue)}
            color="primary"
            autoFocus
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InspcTrgtsList;
